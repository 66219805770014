// https://www.w3.org/TR/mediacapture-streams/#constrainable-properties
<template>
    <v-form
        ref="form"
        v-model="valid"        
    > 
        <v-container
            v-if="loaded"
            fluid
        >
            <v-row>
                <v-col>
                    <h3
                        :class="{'mobileH1':$vuetify.breakpoint.xs}"
                    >
                        {{ $t('fill-application') }}
                    </h3>
                </v-col>
            </v-row>
            <v-row
                justify="center"
            >
                <v-col
                    xs="12"
                    md="8"
                >
                    <v-select
                        v-model="selectedReason"
                        :items="exemmptionReasons"
                        item-text="reason"
                        item-value="id"
                        :label="$t('select-reason')"
                        :menu-props="{ top: true, offsetY: true }"
                        :rules="globalCheckNotEmpty(selectedReason)"  
                        required
                    />
                </v-col>
            </v-row>
               
            <v-row
                justify="center"
            >
                <v-col
                    xs="12"
                    md="8"  
                >
                    <v-text-field
                        v-model="phoneNumber"
                        type="tel"
                        inputmode="tel"
                        autocorrect="off" 
                        autocomplete="tel"
                        :rules="globalPhoneNumberValidator(phoneNumber)"
                        :label="$t('phone-number-no-country-code')"
                        required
                    />
                </v-col>
            </v-row>
            <v-row
                v-if="imageUrl"
                justify="center"
            >
                <v-col
                    xs="12"
                    md="8"
                >
                    <v-img 
                        contain   
                        max-width="1024"                     
                        :src="imageUrl"   
                        class="hoverGrab"                         
                    />
                </v-col>
            </v-row>         
            <v-row
                v-if="allowFileSelection"
                justify="center"
            >
                <v-col
                    cols="12"
                    xs="12"
                    md="8"
                >
                    <v-file-input
                        v-model="image"
                        :rules="imageUploadRules"
                        accept="image/*"
                        :label="$t('select-image-text')"
                        placeholder="Vælg et billede"     
                        prepend-icon=""                   
                        type="file"
                        required
                        @change="onFileChange"
                    />
                </v-col>
            </v-row>
            <v-row
                v-if="!allowFileSelection"
                justify="center"
            >
                <v-col
                    xs="12"
                    md="8"
                >
                    <v-file-input
                        v-model="image"
                        :rules="imageUploadRules"
                        accept="image/png, image/jpeg, image/bmp"
                        :label="$t('select-image-text')"
                        placeholder="Tag et billede"     
                        prepend-icon=""                   
                        capture="camera"
                        required
                        @change="onFileChange"
                    />
                </v-col>
            </v-row>
            <v-row
                justify="center"
            >
                <v-col
                    xs="12"
                    md="8"
                >
                    <v-text-field
                        v-model="exemptionReasonMessage"
                        :label="$t('remark-optional')"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col
                    class="pa-0 ma-0 ml-1"
                >
                    <v-btn
                        elevation="1"
                        tile  
                        block   
                        class="btnWithRoundedCorner btnHeight42px"
                        :small="$vuetify.breakpoint.xs"  
                        :to="{name: 'Index'}"   
                    >
                        {{ $t('cancel') }}
                    </v-btn>
                </v-col>
                <v-col
                    class="pa-0 ma-0 mx-1"
                >
                    <v-btn
                        color="green"
                        elevation="1"
                        class="btnWithRoundedCorner white--text btnHeight42px"
                        tile  
                        block
                        :small="$vuetify.breakpoint.xs" 
                        :loading="btnLoading"
                        :disabled="!valid"
                        @click="sendExempt"
                    >
                        {{ $t('send-application') }}
                    </v-btn>                   
                </v-col>
            </v-row>
        </v-container>
        <v-container
            v-else
        >
            <v-row
                justify="center"
            >
                <loading />
            </v-row>
            <v-row
                justify="center"
            >
                <v-col
                    class="pa-0 ma-0"
                >
                    <v-btn
                        class="mt-5 btnWithRoundedCorner btnHeight42px"
                        elevation="1"
                        tile      
                        x-large    
                        :to="{name: 'Index'}"
                    >
                        {{ $t('go-back') }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
import {mapActions } from 'vuex'
export default {
    name:'ExemptionRequest',
    components:{
        Loading: () => import('@/components/Loading')
    },
    data(){
        return{
            loaded:false,
            btnLoading: false,
            valid:true,
            rowId: '',
            exemmptionReasons: [],
            exemptionReasonMessage: '',
            selectedReason: null,
            allowFileSelection: false,
            imageUploadRules: [
                value => !value || value.size < this.maxImageSize || this.$t('image-too-big'),
                v => !!v || this.$t('image-upload'),
                v => (v && v.size > 0) || this.$t('image-upload')
            ],
            image: undefined,
            phoneNumber: null,
            imageUrl: null,
            base64ImageData: null,
            maxImageSize: 20000000
        }
    },       
    watch:{
        selectedReason(value){
            if(this.exemmptionReasons.find(x => x.id == value && x.allowFileSelection == true)){
                this.allowFileSelection = true
            }else{
                this.allowFileSelection = false
            }
        },
        imageUrl(value){            
            if(value){
                this.base64ImageData= this.getBase64ImageStringFromUrl(value)
            }
        }
    },
    beforeMount(){
        this.GetRegistrationInformation().then(result => 
        { 
            if(result)
            {
                this.rowId = result.id
            }})
        this.FetchExemptionReasons().then(success => {
            if(success){
                this.GetExemptionReasons().then(result => this.exemmptionReasons = result)
                
            }                    
        })
        this.loaded = true
    },
    mounted(){
        this.$vuetify.goTo(0, {
            duration: 300,
            offset: 0,
            easing: 'easeInOutCubic',
        })
    },
    methods:{
        sendExempt(){
            if(this.$refs.form.validate())
            {
                if(!this.rowId){
                    alert(this.$t('application-error'))
                }else{  
                    this.btnLoading = true                  
                    let input = {
                        registrationId: this.rowId,
                        exemptionReasonId: this.selectedReason,
                        phoneNumber: this.phoneNumber,
                        base64Image: this.base64ImageData,
                        exemptionReasonMessage: this.exemptionReasonMessage
                    }  
                    this.SendExemptionRequest(input).then(response => {
                        if(response.data){
                            this.btnLoading = false
                            if(response.data.success){ 
                                this.clearFormData()   
                                this.$router.push('success')
                            }
                            else{
                                this.$router.push({name: '400', params: {textLine1: this.$t('application-error-400'), textLine2: this.$t('application-error-contact-support'), error: true}})
                            }
                        }else{
                            this.$router.push({name: '400', params: {textLine1: this.$t('application-error-400'), textLine2: this.$t('application-error-contact-support'), error: true}})
                        }
                    })
                } 
            }

        },
        createImage(file) {
            const reader = new FileReader()

            reader.onload = e => {
                this.imageUrl = e.target.result
            }
            reader.readAsDataURL(file)
        },
        onFileChange(file) {
            if (!file || file.size > this.maxImageSize) {
                this.imageUrl = null
                this.image = file
                return
            }
            this.createImage(file)
        },
        clearFormData(){
            this.ClearRegistrationState()
            this.ClearLicensePlateAndCVRDataState()
            this.ClearExemptionReasons()
            this.$refs.form.reset()
        },
        getBase64ImageStringFromUrl(imageUrl){
            let stringArray = imageUrl.split(',')
            return stringArray[1]
        },
        ...mapActions({
            FetchExemptionReasons:'FetchExemptionReasons',
            ClearRegistrationState: 'ClearRegistrationState',
            ClearLicensePlateAndCVRDataState: 'ClearLicensePlateAndCVRDataState',
            ClearExemptionReasons: 'ClearExemptionReasons',
            SendExemptionRequest: 'SendExemptionRequest',
            GetExemptionReasons: 'GetExemptionReasons',
            GetRegistrationInformation:'GetRegistrationInformation'
        })
    }
}
</script>
<style scoped>
.container{
    margin-top: 60px;
}
</style>